import React, {useState} from 'react';
import './App.css';
import {Button, Col, Container} from 'reactstrap';
import AndroidImage from './assets/images/android.png';
import IosImage from './assets/images/ios.png';
import Ios1 from './assets/pozadine/slims-ios-01.png';
import Ios2 from './assets/pozadine/slims-ios-02.png';
import Ios3 from './assets/pozadine/slims-ios-03.png';
import Ios4 from './assets/pozadine/slims-ios-04.png';
import { LazyLoadImage } from "react-lazy-load-image-component";

import Android1 from './assets/pozadine/slims-android-01.png';
import Android2 from './assets/pozadine/slims-android-02.png';
import Android3 from './assets/pozadine/slims-android-03.png';
import Android4 from './assets/pozadine/slims-android-04.png';
import { MdArrowBackIosNew } from "react-icons/md";

function App() {
    const [iosImage, setIosImage] = useState<boolean>(false);
    const [androidImage, setAndroidImage] = useState<boolean>(false);
    const [home, setHome] = useState<boolean>(true);
    const homeContent = () => {
        return (<>
            <Col xl={6} lg={6} md={12} xs={12} className={'mt-auto'}>
                <div className={'d-flex flex-wrap buttons-sobranie mb-200 justify-content-center'}>
                    <Col xl={6} lg={6} md={8} xs={8}>
                        <LazyLoadImage className={'android-image image w-100 p-2'} src={AndroidImage} alt={'android'}
                             onClick={() => {
                                 setAndroidImage(true);
                                 setHome(false)
                             }}/>
                    </Col>
                    <Col xl={6} lg={6} md={8} xs={8}>
                        <LazyLoadImage className={'ios-image image w-100 p-2'} src={IosImage} alt={'IOS'} onClick={() => {
                            setIosImage(true);
                            setHome(false)
                        }}/>
                    </Col>
                </div>
            </Col>
        </>)

    }
    const iosImages = () => {
        return <>
            <Col xl={8} lg={8} xs={11} md={11} className={'mt-auto mb-auto'}>
                <Button onClick={() => {
                    setHome(true);
                    setIosImage(false);
                    setAndroidImage(false);
                }}>
                    <MdArrowBackIosNew />
                    Nazad</Button>
                <div className={'d-flex flex-wrap'}>
                    <Col xl={6} lg={6} md={6} xs={6}>
                        <LazyLoadImage src={Ios1} className={'w-100 download-image'} onClick={() => handleDownloadImage(Ios1, 'ios-01.png')}/>
                    </Col>
                    <Col xl={6} lg={6} md={6} xs={6}>
                        <LazyLoadImage src={Ios2} className={'w-100  download-image'} onClick={() => handleDownloadImage(Ios2, 'ios-02.png')}/>
                    </Col>
                    <Col xl={6} lg={6} md={6} xs={6}>
                        <LazyLoadImage src={Ios3} className={'w-100 download-image'} onClick={() => handleDownloadImage(Ios3, 'ios-03.png')}/>
                    </Col>
                    <Col xl={6} lg={6} md={6} xs={6}>
                        <LazyLoadImage src={Ios4} className={'w-100 download-image'} onClick={() => handleDownloadImage(Ios4, 'ios-04.png')}/>
                    </Col>
                </div>
            </Col>
        </>
    }
    const androidImages = () => {
        return <>
            <Col xl={8} lg={8} xs={11} md={11} className={'mt-auto mb-auto'}>
                <Button onClick={() => {
                    setHome(true);
                    setIosImage(false);
                    setAndroidImage(false);
                }}>
                    <MdArrowBackIosNew/>
                    Nazad</Button>

                <div className={'d-flex flex-wrap'}>
                    <Col xl={6} lg={6} md={6} xs={6}>
                        <LazyLoadImage src={Android1} className={'w-100 download-image'} onClick={() => handleDownloadImage(Android1, 'android-01.png')}/>

                    </Col>
                    <Col xl={6} lg={6} md={6} xs={6}>
                        <LazyLoadImage src={Android2} className={'w-100 download-image'}  onClick={() => handleDownloadImage(Android2, 'android-02.png')}/>

                    </Col>
                    <Col xl={6} lg={6} md={6} xs={6}>
                        <LazyLoadImage src={Android3} className={'w-100 download-image'}  onClick={() => handleDownloadImage(Android1, 'android-03.png')}/>

                    </Col>
                    <Col xl={6} lg={6} md={6} xs={6}>
                        <LazyLoadImage src={Android4} className={'w-100 download-image'} onClick={() => handleDownloadImage(Android4, 'android-04.png')} />

                    </Col>
                </div>
            </Col></>
    }
    const handleDownloadImage = (imageUrl: any, imageName: string) => {

        const link = document.createElement('a');
        link.href = imageUrl;
        link.setAttribute('download', imageName);
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup
        document.body.removeChild(link);
    };
    return (
        <Container>
            <div className={ home ? 'home-background d-flex flex-wrap home-row justify-content-center' : 'download-images-background d-flex flex-wrap home-row justify-content-center'}>
                {
                    home && homeContent()
                }
                {
                    iosImage && iosImages()
                }
                {
                    androidImage && androidImages()
                }
            </div>
        </Container>
    );
}

export default App;
